@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600|Roboto:400,500,700|Quicksand:400,500);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 70vh;
  min-height: 550px;
  position: relative;
  background: rgba(241, 231, 254, 1);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 70% 18vh;
  box-sizing: border-box;
  border-bottom: none;
}

.hero .title .text,
.hero .title .image-wrap {
  display: block;
  float: left;
}

.hero .title .text {
  margin-right: 48px;
}

.hero .image {
  margin: -113px 0 0;
}

.hero .title {
  /* width: 80%;
  margin: 0 0 0 20%; */
  top: 20%;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.hero .title h1 {
  font-size: 1.7em;
  line-height: 1.3em;
  font-weight: 400;
  letter-spacing: 0;
  color: rgba(169, 109, 173, 1);
  height: 120px;
  font-family: 'system-ui', sans-serif;
  clear: both;
}

.hero .title h1 em {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  color: rgba(169, 109, 173, 1);
}

.hero .title h2 {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 40px;
  color: #fff68f;
  background: rgba(169, 109, 173, 0.3);
  padding: 8px 24px 6px;
  display: inline-block;
  border-radius: 4px;
}


.hero .title h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7em;
  color: rgba(169, 109, 173, 1);
}

.hero .title h3 em {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
}

.hero .title a.see-my-work,
.hero .title a.download-my-resume {
  display: block;
  float: left;
  padding: 8px 30px;
  border-radius: 2px;
  margin: 12px 0 0;
  font-size: 1.25em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  background: #36d7b7;
  color: #fff;
  -webkit-transition: 0.5s background linear;
  transition: 0.5s background linear;
  cursor: pointer;
}

.hero .title a.download-my-resume:hover,
.hero .title a.see-my-work:hover {
  background: #1ba39c;
}

.hero .title a.download-my-resume {
  padding: 8px 28px 8px 20px;
  margin-left: 8px;
  background: #9f5afd;
  background: #be90d4;
}

.hero .title a.download-my-resume:hover {
  background: #a537fd;
  background: #a96dad;
}

.hero .title a.contact-me {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

.hero .title a.contact-me:hover {
  opacity: 1;
}

.hero .title a.social {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

.hero .title a.social:hover {
  opacity: 1;
}
.header {
  width: 100%;
  height: 50px;
  position: relative;
  background: rgba(241, 231, 254, 1);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 70% 18vh;
  box-sizing: border-box;
  border-bottom: none;
}

.header a {
  font-size: 1.7em;
  font-weight: 600;
  margin-bottom: 40px;
  color: #fff68f;
  background: rgba(169, 109, 173, 0.3);
  padding: 7px 36px 7px 24px;
  display: inline-block;
  /* border-radius: 4px; */
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.header a:hover {
  color: #fff68f;
  background: rgba(169, 109, 173, 0.8);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.summary,
.how-i-work {
  padding: 96px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff6be;
  background: #3B3B58;
}

.overview {
  /* width: 60%; */
  max-width: 900px;
  margin: 0 auto;
}

.how-i-work {
  background: rgba(241, 231, 254, 1);
}

.overview h1 {
  font-size: 22px;
  letter-spacing: 0.05em;
  margin-bottom: 50px;
  color: #ddd;
}

.year {
  font-size: 22px;
  line-height: 80px;
  color: #E2CFE8;
  font-family: monospace;
}

.desc {
  font-size: 32px;
  color: #fff;
  margin: 12px 0;
}

.desc a {
  color: #fff68f;
}

.desc a:hover {
  color: #fff68f;
  text-decoration: underline;
}

.how-i-work .year {
  color: #aaa;
}

.how-i-work .desc {
  color: rgba(169, 109, 173, 1);
}

.how-i-work .desc a {
  color: #0099ff;
}

.how-i-work .desc a:hover {
  color: #0099ff;
}

.summary .wire {
  position: absolute;
  top: 18%;
  border: 2px solid #a2a8d3;
  border: 2px solid #f2c0ff;
  border: 2px solid #fabe58;
  opacity: 0.3;
  width: 46%;
  height: 84%;
  left: 22%;
}

.summary .content-box {
  position: relative;
  background: #fff;
  color: #333;
  border-radius: 2px;
  padding: 30px 48px;
  width: 100%;
  box-sizing: border-box;
  margin: 96px auto;
  margin: 0px auto;
  font-family: 'Roboto', sans-serif;
  height: 340px;
}

.summary .content-box p {
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 18px;
}

.summary .content-box p:last-child {
  font-weight: 300;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 34px;
}
.contact {
  background: #2e3131;
  padding: 1px 0;
}

.contact form {
  width: 60%;
  max-width: 900px;
  margin: 95px auto 127px;
}

.contact form h1 {
  color: #fff;
  font-size: 36px;
}

.contact form label {
  color: #ddd;
  display: block;
  margin: 0 0 4px;
}

.contact form button:hover,
.contact form button:focus,
.contact form button:active {
  border: 1px solid #a537fd;
  background: #a537fd;
}

.contact form button {
  border: 1px solid #9f5afd;
  background: #9f5afd;
}

.contact button:hover,
.contact button:focus,
.contact button:active {
  border: 1px solid #a537fd;
  background: #a537fd;
}

.contact button {
  border: 1px solid #9f5afd;
  background: #9f5afd;
}
.project-container {
  width: 60%;
  margin: 0 auto;
}

.project {
  margin: 84px auto;
}

.project h2 {
  font-family: "Roboto", sans-serif;
  font-size: 2.5em;
  font-weight: 600;
  letter-spacing: 0.005em;
  color: #333;
  display: inline-block;
  margin: 0 8px 0 0;
}

.project .detail {
  max-width: 888px;
  /* margin:12px 0 0; */
}

.project span.bridge {
  font-size: 14px;
  display: inline-block;
  margin: 0 2px 0 0;
  font-family: "Quicksand", sans-serif;
  color: #aaa;
}

.project span.subtitle {
  font-size: 15px;
  display: inline-block;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #999;
}

.project .link a {
  font-size: 16px;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
}

.project .link a:hover {
  text-decoration: underline;
}

.project .description {
  margin: 6px 0 24px;
}

.project .description p {
  vertical-align: top;
  display: inline-block;
  max-width: 800px;
  color: #333;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 32px;
  /* padding-left:16px; */
}

.project .tags {
  /* display:inline-block; */
  /* vertical-align:top; */
  display: block;
  /* margin-left: 18px; */
  max-width: 400px;
}

.project .tags span {
  font-size: 0.9em;
  display: inline-block;
  margin: 0 6px 6px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding: 4px 8px;
}

.project .tags .role {
  background: #ffffcc;
}

.golang,
.javascript,
.python,
.ruby,
.c,
.reactjs,
.relayjs,
.mysql,
.unreal-engine-4,
.redis,
.rails,
.objective-c,
.php {
  background: #f1e7fe;
}

.nlp,
.machine-learning,
.data-science,
.text-extraction,
.computer-vision,
.threed,
.physics,
.game-development,
.game-design,
.accounting,
.hr,
.leadership,
.design,
.process,
.product,
.insurance,
.education,
.math,
.gaming,
.drones,
.imagery {
  background: #c8f7c5;
}

.steam,
.ps4,
.graphql,
.dev-ops,
.kubernetes,
.gcp,
.aws,
.digital-ocean,
.docker,
.networking {
  background: #e4f1fe;
}

.project .slides {
  /* float: left; */
  margin-top: 20px;
  clear: both;
  display: block;
  width: 720px;
  /* display:inline-block; */
  /* vertical-align:top; */
  position: relative;
}

.project .slides .frame {
  width: 720px;
}

.video-slide .video-wrapper,
.project .slides .picture-frame {
  width: 720px;
  height: 400px;
  overflow: hidden;
  display: block;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #eee;
  background: #000;
}

.project .slides img {
  width: 720px;
}

.project .slides .gallery-thumbnail {
  overflow: hidden;
}

.project .slides .gallery-thumbnail img {
  width: 100px;
}

.project .image-gallery-slides {
  width: 720px;
  /* height:640px; */
  height: 760px;
}

.project .image-gallery-slide {}

.project .image-gallery-image {
  width: 720px;
  height: 400px;
}

.project .image-gallery-slide .image-gallery-description {
  /* height:240px; */
  /* height: 320px; */
  max-height: 600px;
  box-sizing: border-box;
  padding: 12px 24px;
  padding: 12px 0;
  border: none;
  position: relative;
  display: block;
  bottom: unset;
  /* display:table; */
  width: 100%;
  background: rgba(255, 249, 222, 0.7);
  background: #333;
  background: #fff;
}

.project .image-gallery-slide .image-gallery-description .content {
  /* display:table-cell; */
  vertical-align: middle;
}

.project .image-gallery-slide .image-gallery-description h6 {
  font-size: 15px;
  line-height: 17px;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  /* color:#fff; */
  /* background:#fafafa; */
  /* border:1px solid #555; */
  color: #333;
  /* border-bottom:1px solid #333; */
  /* border-bottom:1px solid #0099ff; */
  display: inline-block;
  /* padding:5px 12px; */
  padding: 0 0 2px 0;
  margin: 8px 0 4px;
  line-height: 24px;
}

.project .image-gallery-slide .image-gallery-description p {
  font-size: 16px;
  line-height: 22px;
  font-family: "Roboto", sans-serif;
  margin: 4px 0 0;
  color: #777;
  /* color:#eee; */
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 32px;
  color: #333;
}

.project .image-gallery-slide .image-gallery-description p i {
  font-style: normal;
  font-family: monospace;
  font-size: 1.1em;
  letter-spacing: -0.04em;
}


.video-slide {
  position: relative;
  cursor: pointer;
}

.video-slide .video-wrapper {
  overflow: hidden;
  height: 400px;
  display: block;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #eee;
}

.video-slide video {
  width: 100%;
}

.hover-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
  z-index: 1;
}

.video-slide:hover .hover-play {
  opacity: 0.6;
}

.video-slide.playing .hover-play {
  opacity: 0;
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}

.hover-play .play-button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 74px;
  border-color: transparent transparent transparent #fff;
  -webkit-transition: 100ms all ease;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 37px 0 37px 60px;
  margin: 160px auto 0;
  cursor: pointer;
}

.hover-play .text {
  color: #fff;
  margin: 24px 0 0 3px;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
}

.project .image-gallery-slide-wrapper .image-gallery-right-nav,
.project .image-gallery-slide-wrapper .image-gallery-left-nav {
  height: 398px;
  top: 200px;
  opacity: 1;
  background: #333;
  -webkit-transition: opacity 0.5s ease, background-color 0.5s ease;
  transition: opacity 0.5s ease, background-color 0.5s ease;
}

.project .image-gallery-slide-wrapper .image-gallery-right-nav {
  border-left: 1px solid white;
}

.project .image-gallery-slide-wrapper .image-gallery-left-nav {
  border-right: 1px solid white;
}

.project .image-gallery-slide-wrapper:hover .image-gallery-right-nav,
.project .image-gallery-slide-wrapper:hover .image-gallery-left-nav {
  /* opacity: 1; */
}

.project .image-gallery-right-nav,
.project .image-gallery-left-nav {
  background: #333;
  height: calc(100% - 2px);
  width: 60px;
  box-sizing: border-box;
  padding: 0 12px;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.project .image-gallery-right-nav:hover,
.project .image-gallery-left-nav:hover {
  background-color: rgba(0, 0, 0, 0.5);
  background: #333;
  opacity: 1;
}

.project .image-gallery-left-nav::before,
.project .image-gallery-right-nav::before {
  opacity: 0.6;
  text-shadow: none;
  font-size: 48px;
  content: "\F3D2";
  color: #fafafa;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.project .image-gallery-right-nav::before {
  content: "\F3D3";
}

.project .image-gallery-left-nav:hover::before,
.project .image-gallery-right-nav:hover::before {
  opacity: 1;
  color: #fff;
}
.about {
  width: 60%;
  background: white;
  margin: 0 auto;
  padding: 96px 0 32px;
}

.about h1 {
  font-size: 36px;
  font-weight: 600;
}

.about p {
  padding: 32px 0 64px;
  font-size: 24px;
  border-bottom: 1px solid #ccc;
  width: 90%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 42px;
}

.about p:last-child {
  border-bottom: none;
}
* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

.app {
}

.app header {
  position:fixed;
  top:0;
  right:0;
  z-index:9999;
}

.app header a {
  font-family:'Open Sans', sans-serif;
  color:#fff;
  display:inline-block;
  padding:16px;
  vertical-align:middle;
  text-transform:uppercase;
  font-size:0.8em;
  letter-spacing:0.05em;
  font-weight:600;
  -webkit-transition:0.5s opacity linear;
  transition:0.5s opacity linear;
  opacity:0.5;
  cursor:pointer;
}

.app header a:hover {
  opacity:1;
}

.app header a:last-child {
  margin-right:16px;
}

/* narrow screens */
@media (max-width:1480px) {
  .hero {
    background-size: 400px;
    background-position: 70% 20vh;
  }

  .project .slides {
    display: block;
    float: none;
    clear: both;
  }

  .project .tags {
    display: block;
    float: none;
    clear: both;
    width: 500px;
    margin: -24px 0 24px 0;
  }

  .summary .content-box {
    width: 100%;
  }

  .summary .content-box p {}

  .summary .content-box p:last-child {
    font-size: 18px;
    line-height: 28px;
  }
}

/* big phones */
@media (max-width:1200px) {
  .hero .title {
    top: 5%;
    width: 90%;
    margin: 0 0 0 5%;
    position: relative;
    height: 100%;
  }

  .how-i-work .overview,
  .summary .overview {
    width: 90%;
    margin: 0 0 0 5%;
  }

  .hero .title h1 {
    font-size: 2em;
    line-height: 1.2em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title .text,
  .hero .title .image-wrap {
    display: block;
    float: none;
  }

  .hero .title .image-wrap {
    position: absolute;
    bottom: 5%;
  }

  .hero .image {
    margin: -200px 0 0;
  }

  .hero .title .image-wrap img {
    width: 65%;
    max-width: 600px;
  }

  .summary {
    height: auto;
    padding: 32px 0;
  }

  .summary .ant-row {}

  .summary .ant-col-8 {
    display: block;
    width: 100%;
    padding: 0 !important;
  }

  .summary .content-box {
    padding: 24px;
    width: 100%;
    margin: 12px auto 6px;
    height: auto;
  }

  .summary .content-box p:first-child {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0.8em;
  }

  .summary .content-box p {
    font-weight: 300;
    font-size: 15px;
    line-height: 21px;
  }

  .summary .content-box p:last-child {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .summary .wire {
    top: 24px;
    height: 90%;
  }


  .work .header {
    width: 80%;
  }

  .work .projects {
    width: 80%;
  }

  .project span.subtitle {
    font-size: 18px;
    display: block;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    color: #888;
    margin: 0 0 2px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .project .description p {
    margin: 1em 0;
  }

  .project .slides {
    width: 100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width: 100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width: 100%;
    height: 40%;
  }

  .project .image-gallery-slides {
    width: 100%;
    height: 60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top: 80px;
    height: 60px;
    width: 60px;
    text-align: center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right: -32px;
    padding: 0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left: -32px;
    padding: 0 8px 0 0;
  }
}

/* small phones */
@media (max-width:480px) {
  .hero {
    height: 60vh;
    min-height: 550px;
  }

  .hero .title {
    top: 5%;
    width: 85%;
    margin: 0 0 0 12%;
    padding-right: 4px;
  }

  .hero .title h2 {
    font-size: 1.3em;
  }

  .hero .title h2 span {
    font-size: 1.3em;
  }

  .hero .title h2 a {
    font-size: 24px;
    margin: 0 0 0 12px;
  }

  .hero .title h1 {
    font-size: 1.5em;
    line-height: 1.2em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h1 span {
    display: inline-block;
    margin-top: 22px;
  }

  .hero .title h3 {
    font-size: 1.1em;
    line-height: 1.1em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h3 span {
    display: inline-block;
    margin-top: 8px;
  }

  .hero .image {
    margin: -260px 0 0;
  }

  .hero .title a.see-my-work {
    width: 100%;
    text-align: center;
  }

  .hero .title a.contact-me {
    font-size: 22px;
  }

  .how-i-work .overview,
  .summary .overview {
    width: 85%;
    margin: 0 0 0 12%;
  }

  .summary .overview h1 {
    font-size: 16px;
    margin-bottom: 50px;
  }

  .how-i-work .overview .col-year,
  .how-i-work .overview .col-desc,
  .summary .overview .col-year,
  .summary .overview .col-desc {
    display: block;
    float: none;
    width: 100%;
  }

  .how-i-work .overview .year,
  .summary .overview .year {
    font-size: 16px;
    line-height: normal;
  }

  .how-i-work .overview .desc,
  .summary .overview .desc {
    font-size: 24px;
    line-height: normal;
    margin: 12px 0 50px;
  }

  .how-i-work .overview .desc span {
    display: block;
  }

  .about {
    width: 85%;
    padding: 36px 0 0;
  }

  .about h1 {
    font-size: 30px;
    margin-bottom: 0;
  }

  .about p {
    font-size: 22px;
    line-height: 36px;
    width: 95%;
  }

  .work {
    padding-top: 36px;
    margin-top: 0;
  }

  .work .header {
    width: 80%;
    margin: 18px auto 0;
  }

  .work .header h1 {
    font-size: 1.5em;
    display: block;
    padding: 4px 16px;
    color: #36d7b7;
    background: transparent;
    padding: 0;
    border-bottom: 1px solid #36d7b7;
  }

  .work .projects {
    width: 80%;
    margin: 60px auto 0;
  }

  .project-container {
    width: 85%;
    margin: 0 auto;
  }

  .project {
    margin: 24px 0;
  }

  .project h2 {
    font-size: 1.8em;
  }

  .project span.subtitle {
    font-size: 18px;
    display: block;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    color: #888;
    margin: 0 0 2px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .project .description p {
    margin: 1em 0;
  }

  .project .slides {
    width: 100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width: 100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width: 100%;
    height: 40%;
  }

  .project .image-gallery-slides {
    width: 100%;
    height: 60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top: 80px;
    height: 60px;
    width: 60px;
    text-align: center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right: -32px;
    padding: 0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left: -32px;
    padding: 0 8px 0 0;
  }

  .project .image-gallery-slide .image-gallery-description h6 {
    font-size: 12px;
    text-transform: uppercase;
  }

  .project .image-gallery-slide .image-gallery-description p {
    font-size: 16px;
    line-height: 24px;
  }

  .project .slides {
    display: block;
    float: none;
    clear: both;
  }

  .project .tags {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    margin: -24px 0 24px 0;
  }

  .contact {}

  .contact form {
    width: 85%;
    margin: 45px auto 80px;
  }

  .contact form button {
    width: 100%;
  }
}

/* mini phones */
@media (max-width:400px) {
  .hero .title {
    top: 5%;
    width: 90%;
    margin: 0 0 0 7%;
    padding-right: 4px;
  }

  .hero .title h2 {
    font-size: 1.3em;
  }

  .hero .title h2 span {
    font-size: 1.3em;
  }

  .hero .title h2 a {
    font-size: 24px;
    margin: 0 0 0 12px;
  }

  .hero .title h1 {
    font-size: 1.5em;
    line-height: 1.2em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h1 span {
    display: inline-block;
    margin-top: 22px;
  }

  .hero .title h3 {
    font-size: 1em;
    line-height: 1.1em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h3 span {
    display: inline-block;
    margin-top: 8px;
  }

  .hero .image {
    margin: -200px 0 0;
  }

  .how-i-work .overview,
  .summary .overview {
    width: 90%;
    margin: 0 0 0 7%;
  }
}
