/* narrow screens */
@media (max-width:1480px) {
  .hero {
    background-size: 400px;
    background-position: 70% 20vh;
  }

  .project .slides {
    display: block;
    float: none;
    clear: both;
  }

  .project .tags {
    display: block;
    float: none;
    clear: both;
    width: 500px;
    margin: -24px 0 24px 0;
  }

  .summary .content-box {
    width: 100%;
  }

  .summary .content-box p {}

  .summary .content-box p:last-child {
    font-size: 18px;
    line-height: 28px;
  }
}

/* big phones */
@media (max-width:1200px) {
  .hero .title {
    top: 5%;
    width: 90%;
    margin: 0 0 0 5%;
    position: relative;
    height: 100%;
  }

  .how-i-work .overview,
  .summary .overview {
    width: 90%;
    margin: 0 0 0 5%;
  }

  .hero .title h1 {
    font-size: 2em;
    line-height: 1.2em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title .text,
  .hero .title .image-wrap {
    display: block;
    float: none;
  }

  .hero .title .image-wrap {
    position: absolute;
    bottom: 5%;
  }

  .hero .image {
    margin: -200px 0 0;
  }

  .hero .title .image-wrap img {
    width: 65%;
    max-width: 600px;
  }

  .summary {
    height: auto;
    padding: 32px 0;
  }

  .summary .ant-row {}

  .summary .ant-col-8 {
    display: block;
    width: 100%;
    padding: 0 !important;
  }

  .summary .content-box {
    padding: 24px;
    width: 100%;
    margin: 12px auto 6px;
    height: auto;
  }

  .summary .content-box p:first-child {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0.8em;
  }

  .summary .content-box p {
    font-weight: 300;
    font-size: 15px;
    line-height: 21px;
  }

  .summary .content-box p:last-child {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .summary .wire {
    top: 24px;
    height: 90%;
  }


  .work .header {
    width: 80%;
  }

  .work .projects {
    width: 80%;
  }

  .project span.subtitle {
    font-size: 18px;
    display: block;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    color: #888;
    margin: 0 0 2px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .project .description p {
    margin: 1em 0;
  }

  .project .slides {
    width: 100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width: 100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width: 100%;
    height: 40%;
  }

  .project .image-gallery-slides {
    width: 100%;
    height: 60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top: 80px;
    height: 60px;
    width: 60px;
    text-align: center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right: -32px;
    padding: 0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left: -32px;
    padding: 0 8px 0 0;
  }
}

/* small phones */
@media (max-width:480px) {
  .hero {
    height: 60vh;
    min-height: 550px;
  }

  .hero .title {
    top: 5%;
    width: 85%;
    margin: 0 0 0 12%;
    padding-right: 4px;
  }

  .hero .title h2 {
    font-size: 1.3em;
  }

  .hero .title h2 span {
    font-size: 1.3em;
  }

  .hero .title h2 a {
    font-size: 24px;
    margin: 0 0 0 12px;
  }

  .hero .title h1 {
    font-size: 1.5em;
    line-height: 1.2em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h1 span {
    display: inline-block;
    margin-top: 22px;
  }

  .hero .title h3 {
    font-size: 1.1em;
    line-height: 1.1em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h3 span {
    display: inline-block;
    margin-top: 8px;
  }

  .hero .image {
    margin: -260px 0 0;
  }

  .hero .title a.see-my-work {
    width: 100%;
    text-align: center;
  }

  .hero .title a.contact-me {
    font-size: 22px;
  }

  .how-i-work .overview,
  .summary .overview {
    width: 85%;
    margin: 0 0 0 12%;
  }

  .summary .overview h1 {
    font-size: 16px;
    margin-bottom: 50px;
  }

  .how-i-work .overview .col-year,
  .how-i-work .overview .col-desc,
  .summary .overview .col-year,
  .summary .overview .col-desc {
    display: block;
    float: none;
    width: 100%;
  }

  .how-i-work .overview .year,
  .summary .overview .year {
    font-size: 16px;
    line-height: normal;
  }

  .how-i-work .overview .desc,
  .summary .overview .desc {
    font-size: 24px;
    line-height: normal;
    margin: 12px 0 50px;
  }

  .how-i-work .overview .desc span {
    display: block;
  }

  .about {
    width: 85%;
    padding: 36px 0 0;
  }

  .about h1 {
    font-size: 30px;
    margin-bottom: 0;
  }

  .about p {
    font-size: 22px;
    line-height: 36px;
    width: 95%;
  }

  .work {
    padding-top: 36px;
    margin-top: 0;
  }

  .work .header {
    width: 80%;
    margin: 18px auto 0;
  }

  .work .header h1 {
    font-size: 1.5em;
    display: block;
    padding: 4px 16px;
    color: #36d7b7;
    background: transparent;
    padding: 0;
    border-bottom: 1px solid #36d7b7;
  }

  .work .projects {
    width: 80%;
    margin: 60px auto 0;
  }

  .project-container {
    width: 85%;
    margin: 0 auto;
  }

  .project {
    margin: 24px 0;
  }

  .project h2 {
    font-size: 1.8em;
  }

  .project span.subtitle {
    font-size: 18px;
    display: block;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    color: #888;
    margin: 0 0 2px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .project .description p {
    margin: 1em 0;
  }

  .project .slides {
    width: 100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width: 100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width: 100%;
    height: 40%;
  }

  .project .image-gallery-slides {
    width: 100%;
    height: 60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top: 80px;
    height: 60px;
    width: 60px;
    text-align: center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right: -32px;
    padding: 0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left: -32px;
    padding: 0 8px 0 0;
  }

  .project .image-gallery-slide .image-gallery-description h6 {
    font-size: 12px;
    text-transform: uppercase;
  }

  .project .image-gallery-slide .image-gallery-description p {
    font-size: 16px;
    line-height: 24px;
  }

  .project .slides {
    display: block;
    float: none;
    clear: both;
  }

  .project .tags {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    margin: -24px 0 24px 0;
  }

  .contact {}

  .contact form {
    width: 85%;
    margin: 45px auto 80px;
  }

  .contact form button {
    width: 100%;
  }
}

/* mini phones */
@media (max-width:400px) {
  .hero .title {
    top: 5%;
    width: 90%;
    margin: 0 0 0 7%;
    padding-right: 4px;
  }

  .hero .title h2 {
    font-size: 1.3em;
  }

  .hero .title h2 span {
    font-size: 1.3em;
  }

  .hero .title h2 a {
    font-size: 24px;
    margin: 0 0 0 12px;
  }

  .hero .title h1 {
    font-size: 1.5em;
    line-height: 1.2em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h1 span {
    display: inline-block;
    margin-top: 22px;
  }

  .hero .title h3 {
    font-size: 1em;
    line-height: 1.1em;
    height: auto;
    letter-spacing: 0;
  }

  .hero .title h3 span {
    display: inline-block;
    margin-top: 8px;
  }

  .hero .image {
    margin: -200px 0 0;
  }

  .how-i-work .overview,
  .summary .overview {
    width: 90%;
    margin: 0 0 0 7%;
  }
}