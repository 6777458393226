.hero {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 70vh;
  min-height: 550px;
  position: relative;
  background: rgba(241, 231, 254, 1);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 70% 18vh;
  box-sizing: border-box;
  border-bottom: none;
}

.hero .title .text,
.hero .title .image-wrap {
  display: block;
  float: left;
}

.hero .title .text {
  margin-right: 48px;
}

.hero .image {
  margin: -113px 0 0;
}

.hero .title {
  /* width: 80%;
  margin: 0 0 0 20%; */
  top: 20%;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.hero .title h1 {
  font-size: 1.7em;
  line-height: 1.3em;
  font-weight: 400;
  letter-spacing: 0;
  color: rgba(169, 109, 173, 1);
  height: 120px;
  font-family: 'system-ui', sans-serif;
  clear: both;
}

.hero .title h1 em {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  color: rgba(169, 109, 173, 1);
}

.hero .title h2 {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 40px;
  color: #fff68f;
  background: rgba(169, 109, 173, 0.3);
  padding: 8px 24px 6px;
  display: inline-block;
  border-radius: 4px;
}


.hero .title h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7em;
  color: rgba(169, 109, 173, 1);
}

.hero .title h3 em {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
}

.hero .title a.see-my-work,
.hero .title a.download-my-resume {
  display: block;
  float: left;
  padding: 8px 30px;
  border-radius: 2px;
  margin: 12px 0 0;
  font-size: 1.25em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  background: #36d7b7;
  color: #fff;
  transition: 0.5s background linear;
  cursor: pointer;
}

.hero .title a.download-my-resume:hover,
.hero .title a.see-my-work:hover {
  background: #1ba39c;
}

.hero .title a.download-my-resume {
  padding: 8px 28px 8px 20px;
  margin-left: 8px;
  background: #9f5afd;
  background: #be90d4;
}

.hero .title a.download-my-resume:hover {
  background: #a537fd;
  background: #a96dad;
}

.hero .title a.contact-me {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

.hero .title a.contact-me:hover {
  opacity: 1;
}

.hero .title a.social {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

.hero .title a.social:hover {
  opacity: 1;
}