.header {
  width: 100%;
  height: 50px;
  position: relative;
  background: rgba(241, 231, 254, 1);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 70% 18vh;
  box-sizing: border-box;
  border-bottom: none;
}

.header a {
  font-size: 1.7em;
  font-weight: 600;
  margin-bottom: 40px;
  color: #fff68f;
  background: rgba(169, 109, 173, 0.3);
  padding: 7px 36px 7px 24px;
  display: inline-block;
  /* border-radius: 4px; */
  transition: all 0.2s ease-in-out;
}

.header a:hover {
  color: #fff68f;
  background: rgba(169, 109, 173, 0.8);
  transition: all 0.2s ease-in-out;
}