.about {
  width: 60%;
  background: white;
  margin: 0 auto;
  padding: 96px 0 32px;
}

.about h1 {
  font-size: 36px;
  font-weight: 600;
}

.about p {
  padding: 32px 0 64px;
  font-size: 24px;
  border-bottom: 1px solid #ccc;
  width: 90%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 42px;
}

.about p:last-child {
  border-bottom: none;
}