.contact {
  background: #2e3131;
  padding: 1px 0;
}

.contact form {
  width: 60%;
  max-width: 900px;
  margin: 95px auto 127px;
}

.contact form h1 {
  color: #fff;
  font-size: 36px;
}

.contact form label {
  color: #ddd;
  display: block;
  margin: 0 0 4px;
}

.contact form button:hover,
.contact form button:focus,
.contact form button:active {
  border: 1px solid #a537fd;
  background: #a537fd;
}

.contact form button {
  border: 1px solid #9f5afd;
  background: #9f5afd;
}

.contact button:hover,
.contact button:focus,
.contact button:active {
  border: 1px solid #a537fd;
  background: #a537fd;
}

.contact button {
  border: 1px solid #9f5afd;
  background: #9f5afd;
}