@import '~antd/dist/antd.css';
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600|Roboto:400,500,700|Quicksand:400,500');


* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

.app {
}

.app header {
  position:fixed;
  top:0;
  right:0;
  z-index:9999;
}

.app header a {
  font-family:'Open Sans', sans-serif;
  color:#fff;
  display:inline-block;
  padding:16px;
  vertical-align:middle;
  text-transform:uppercase;
  font-size:0.8em;
  letter-spacing:0.05em;
  font-weight:600;
  transition:0.5s opacity linear;
  opacity:0.5;
  cursor:pointer;
}

.app header a:hover {
  opacity:1;
}

.app header a:last-child {
  margin-right:16px;
}
