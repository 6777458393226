.summary,
.how-i-work {
  padding: 96px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff6be;
  background: #3B3B58;
}

.overview {
  /* width: 60%; */
  max-width: 900px;
  margin: 0 auto;
}

.how-i-work {
  background: rgba(241, 231, 254, 1);
}

.overview h1 {
  font-size: 22px;
  letter-spacing: 0.05em;
  margin-bottom: 50px;
  color: #ddd;
}

.year {
  font-size: 22px;
  line-height: 80px;
  color: #E2CFE8;
  font-family: monospace;
}

.desc {
  font-size: 32px;
  color: #fff;
  margin: 12px 0;
}

.desc a {
  color: #fff68f;
}

.desc a:hover {
  color: #fff68f;
  text-decoration: underline;
}

.how-i-work .year {
  color: #aaa;
}

.how-i-work .desc {
  color: rgba(169, 109, 173, 1);
}

.how-i-work .desc a {
  color: #0099ff;
}

.how-i-work .desc a:hover {
  color: #0099ff;
}

.summary .wire {
  position: absolute;
  top: 18%;
  border: 2px solid #a2a8d3;
  border: 2px solid #f2c0ff;
  border: 2px solid #fabe58;
  opacity: 0.3;
  width: 46%;
  height: 84%;
  left: 22%;
}

.summary .content-box {
  position: relative;
  background: #fff;
  color: #333;
  border-radius: 2px;
  padding: 30px 48px;
  width: 100%;
  box-sizing: border-box;
  margin: 96px auto;
  margin: 0px auto;
  font-family: 'Roboto', sans-serif;
  height: 340px;
}

.summary .content-box p {
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 18px;
}

.summary .content-box p:last-child {
  font-weight: 300;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 34px;
}